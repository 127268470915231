import { useEffect } from 'react'

import useSourcesContext from 'context/sources'

export default function useSources(document) {
  const setSources = useSourcesContext(s => s.setSources)
  const setLastPublicationDate = useSourcesContext(s => s.setLastPublicationDate)
  const setAuthor = useSourcesContext(s => s.setAuthor)

  useEffect(() => {
    if (document?.data?.sources) setSources(document.data.sources)
  }, [document.data?.sources, setSources])

  useEffect(() => {
    if (document?.last_publication_date) setLastPublicationDate(document.last_publication_date)
  }, [document?.last_publication_date, setLastPublicationDate])

  useEffect(() => {
    if (document?.data?.author) setAuthor(document.data.author)
  }, [document?.data?.author, setAuthor])
}
