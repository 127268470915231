import React, { useEffect, useRef, useCallback } from 'react'
import classNames from 'classnames/bind'
import { motion, AnimatePresence } from 'framer-motion'
import { ref, update, increment } from 'firebase/database'
import { easeSinOut } from 'd3-ease'

import { FactHeader } from 'components/ui/Header'
import ScrollIndicator from 'components/ui/ScrollIndicator'
import SEO from 'components/core/SEO'
import SliceRenderer from 'components/core/SliceRenderer'
import useTheme from 'lib/useTheme'
import useSources from 'lib/useSources'
import useCopy from 'lib/useCopy'
import useIsDesktop from 'lib/useIsDesktop'
import writeToLocalStorage from 'lib/writeToLocalStorage'
import readFromLocalStorage from 'lib/readFromLocalStorage'
import { useDatabase, useUser } from 'lib/firebase'
import CardsLayoutTrigger from 'components/ui/CardsLayout/CardsLayoutTrigger'
import ViewportEnter from 'components/core/ViewportEnter'
import useUIContext from 'context/ui'
import useFirebaseContext from 'context/firebase'

import { FactPageTypes } from './FactPageTypes'
import * as s from './FactPage.module.scss'
const cn = classNames.bind(s)

const FactPage = ({ data }: FactPageTypes) => {
  /* Hooks are not allowed to be called conditionally
   * and needs to be called before any early return
   */
  const theme = data?.prismicFact.data?.theme_color || ''
  const themeSecondary = data?.prismicFact.data?.theme_color_secondary || ''
  const document = data?.prismicFact || {}
  const uid = document.uid || ''
  const firebaseId = document.id || ''
  const isDesktop = useIsDesktop()

  /* Firebase */
  const database = useDatabase()
  const user = useUser()
  const setEarned = useFirebaseContext(s => s.setEarnedVotes)
  const earned = useFirebaseContext(s => s.earnedVotes)
  const setActiveFactFirebaseId = useUIContext(s => s.setActiveFactFirebaseId)

  const setActiveFactId = useUIContext(s => s.setActiveFactId)
  const setIsFactPageScrollable = useUIContext(s => s.setIsFactPageScrollable)
  const setViewedVoteNotification = useUIContext(s => s.setViewedVoteNotification)
  const setReadStatusUpdated = useUIContext(s => s.setReadStatusUpdated)
  const factPage = useRef<HTMLDivElement>(null)

  const setFactPageTransitionEnd = useUIContext(s => s.setFactPageTransitionEnd)
  const setFactPageTransitionStart = useUIContext(s => s.setFactPageTransitionStart)

  useTheme(theme.toLowerCase().replace(' ', '-'), themeSecondary.toLowerCase().replace(' ', '-'))
  useSources(document)
  useCopy(data.prismicSiteSettings?.data)

  useEffect(() => {
    window.scrollTo(0, 0)
    // reset transition from cards to fact page
    setFactPageTransitionStart(false)
    setFactPageTransitionEnd(false)
  }, [uid, setFactPageTransitionStart, setFactPageTransitionEnd])

  useEffect(() => {
    setActiveFactId(uid)
    setActiveFactFirebaseId(firebaseId)
    return () => {
      setActiveFactId(null)
      setActiveFactFirebaseId(null)
      setIsFactPageScrollable(false)
    }
  }, [setActiveFactId, uid, firebaseId, setActiveFactFirebaseId, setIsFactPageScrollable])

  const onAnimationComplete = () => {
    setTimeout(() => {
      if (!factPage.current) return
      factPage?.current.classList.add(cn('scrollable'))
      setIsFactPageScrollable(true)
    }, 300)
  }

  const markAsRead = useCallback(() => {
    const alreadyRead = readFromLocalStorage('readFacts').find(f => f === uid)
    writeToLocalStorage(uid, 'readFacts')
    // @ts-ignore
    setReadStatusUpdated((s: boolean) => !s)

    if (!!alreadyRead || !database || !user) return
    setEarned((earned || 0) + 1)
    setViewedVoteNotification(false)
    update(ref(database), { requestedEarnedVote: increment(1) })
  }, [setReadStatusUpdated, setViewedVoteNotification, uid, database, user, setEarned, earned])

  if (!data) return null
  const scrollText = data.prismicSiteSettings?.data?.scroll_text || ''
  const category = data.prismicHomepage?.data.body.find(cat => {
    return cat.items?.find(item => {
      return item?.category_fact?.uid === data.prismicFact.uid
    })
  })

  return (
    <>
      <SEO
        title={document.data.page_meta_title}
        description={document.data.page_meta_description}
        thumbnail={document.data.page_meta_thumbnail}
      />
      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.2 } }}>
        <ScrollIndicator to='#section-2' hideLabelOnMobile absolute isFactPage>
          {scrollText}
        </ScrollIndicator>
      </motion.div>
      <div className={cn('factPage')} ref={factPage}>
        <FactHeader category={category?.primary.category_title || ''} />
        <AnimatePresence>
          <motion.div
            key={`bgCover-${isDesktop}`}
            className={cn('bgCover')}
            animate={{ opacity: 1 }}
            onAnimationComplete={onAnimationComplete}
          />
        </AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1, transition: { duration: 0.4, delay: 0.1, ease: easeSinOut } }}
          className={cn('sliceRenderer')}
        >
          <SliceRenderer slices={document.data.body} isFactPage />
        </motion.div>
        <ViewportEnter onEnter={markAsRead}>
          <div style={{ position: 'absolute', bottom: '100vh' }} />
        </ViewportEnter>
        <CardsLayoutTrigger />
      </div>
    </>
  )
}

export default FactPage
