import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { linkResolver } from 'prismic/linkResolver'
import FactPage from 'views/FactPage'

import { prismicRepo } from '../../prismic-configuration'

export const query = graphql`
  query FactQuery($lang: String, $uid: String) {
    prismicFact(lang: { eq: $lang }, uid: { eq: $uid }) {
      _previewable
      lang
      uid
      id
      alternate_languages {
        id
        type
        lang
        uid
      }
      last_publication_date(formatString: "D MMMM YYYY", locale: $lang)
      data {
        theme_color
        theme_color_secondary
        page_meta_title
        page_meta_description
        page_meta_thumbnail {
          url
          dimensions {
            height
            width
          }
          alt
        }
        author
        sources {
          source_label
          source_url {
            url
          }
        }
        body {
          ... on PrismicFactDataBodySparkSentenceFullscreen {
            slice_type
            primary {
              layout
              title_layer {
                text
                raw
              }
              foreground_layer {
                alt
                gatsbyImageData
                thumbnails {
                  mobile {
                    gatsbyImageData
                  }
                  card {
                    gatsbyImageData
                  }
                }
              }
              background_layer {
                alt
                gatsbyImageData
                thumbnails {
                  mobile {
                    gatsbyImageData
                  }
                  card {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicFactDataBodySparkSentenceTwoColumn {
            slice_type
            primary {
              layout
              text {
                text
                raw
              }
              foreground_layer {
                alt
                gatsbyImageData
                thumbnails {
                  mobile {
                    gatsbyImageData
                  }
                  card {
                    gatsbyImageData
                  }
                }
              }
              background_layer {
                alt
                gatsbyImageData
                thumbnails {
                  mobile {
                    gatsbyImageData
                  }
                  card {
                    gatsbyImageData
                  }
                }
              }
            }
          }
          ... on PrismicFactDataBodyGeneric {
            slice_type
            primary {
              layout
              animation_aria_label
              animation {
                url
              }
              image {
                gatsbyImageData
                alt
              }
              text {
                raw
              }
              text_extended {
                raw
              }
              popup
              highlight_color
            }
          }
          ... on PrismicFactDataBodyDataVizCrossfadeGeneric {
            slice_type
            primary {
              layout
              highlight_color
            }
            items {
              popup
              text {
                raw
              }
              text_extended {
                raw
              }
              image {
                gatsbyImageData
                alt
              }
              animation {
                url
              }
            }
          }
          ... on PrismicFactDataBodyDataVizCrossfadeTimeline {
            slice_type
            primary {
              layout
            }
            items {
              date: title {
                text
              }
              text {
                raw
              }
              image {
                gatsbyImageData
                alt
              }
              animation {
                url
              }
            }
          }
          ... on PrismicFactDataBodyDataVizUnmaskingTimeline {
            slice_type
            primary {
              layout
              foreground_layer {
                alt
                gatsbyImageData
              }
              background_layer {
                alt
                gatsbyImageData
              }
            }
            items {
              date: title {
                text
              }
              text {
                raw
              }
              unmask_percentage
            }
          }
          ... on PrismicFactDataBodyDataPieChart {
            slice_type
            primary {
              accessibility_label
              layout
              text {
                raw
              }
              text_extended {
                text
              }
              start_angle
            }
            items {
              title
              value
              # 1. Adding an alias is needed due to conflicting types "String" and "PrismicStructuredTextType"
              # when text is retrieved as only a string in items.
              # https://github.com/graphql/graphql-js/issues/522
              pie_popup: popup {
                raw
              }
            }
          }
          ... on PrismicFactDataBodyShortExplanationIcons {
            slice_type
            primary {
              text {
                text
              }
              image {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicFactDataBodyShortExplanationPills {
            slice_type
            primary {
              image {
                alt
                fixed {
                  base64
                }
                thumbnails {
                  mobile {
                    fixed {
                      base64
                    }
                  }
                }
              }
            }
          }
          ... on PrismicFactDataBodyShortExplanationStickers {
            slice_type
            primary {
              text {
                text
              }
            }
            items {
              popup_text {
                text
                raw
              }
              image {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicFactDataBodyShortExplanationOutline {
            slice_type
            primary {
              text {
                raw
              }
            }
            items {
              popup_content
              # 1.
              content_text: text
              content_image: image {
                gatsbyImageData(width: 450)
                alt
              }
            }
          }
          ... on PrismicFactDataBodyFloatingBanner {
            slice_type
            primary {
              number_of_banners
              text {
                text
              }
            }
          }
          ... on PrismicFactDataBodyTakeaway {
            slice_type
            items {
              position
              image {
                alt
                gatsbyImageData
                dimensions {
                  width
                  height
                }
              }
              mobile_image {
                alt
                gatsbyImageData
                dimensions {
                  width
                  height
                }
              }
            }
            primary {
              text {
                text
              }
            }
          }
          ... on PrismicFactDataBodyExampleDragAndReveal {
            slice_type
            primary {
              initial_reveal_offset
              start_title {
                text
              }
              start_text {
                text
              }
              start_image {
                alt
                gatsbyImageData
              }
              end_title {
                text
              }
              end_text {
                text
              }
              end_image {
                alt
                gatsbyImageData
              }
            }
          }
          ... on PrismicFactDataBodyExampleImageBackground {
            slice_type
            primary {
              layout
            }
            items {
              text {
                raw
              }
              image {
                fluid {
                  srcSetWebp
                }
                alt
                gatsbyImageData
                thumbnails {
                  mobile {
                    gatsbyImageData
                    fluid {
                      srcSetWebp
                    }
                  }
                }
              }
            }
          }
          ... on PrismicFactDataBodyData3dGlobe {
            slice_type
            primary {
              layout
            }
            items {
              region_title {
                text
              }
              text {
                raw
                text
              }
              globe_rotation {
                longitude
                latitude
              }
              selected_countries
            }
          }
          ... on PrismicFactDataBodyWorldMap {
            slice_type
            primary {
              section_title {
                raw
              }
              popup_text
              map_color
              blobs_color
            }
            items {
              hotspot_location {
                longitude
                latitude
              }
              hotspot_title
              hotspot_text
              hotspot_size
            }
          }
        }
      }
    }
    prismicHomepage(lang: { eq: $lang }) {
      _previewable
      data {
        body {
          ... on PrismicHomepageDataBodyCategory {
            primary {
              category_title
            }
            items {
              category_fact {
                uid
              }
            }
          }
        }
      }
    }
    prismicSiteSettings(lang: { eq: $lang }) {
      _previewable
      lang
      data {
        author_prefix
        available_votes
        back_to_home
        close
        dialog_description
        hotspot_size_label
        menu
        open
        reaction_aria_label
        scroll_text
        source
        sources
        sources_title
      }
    }
  }
`

export default withPrismicPreview(FactPage, [
  {
    repositoryName: prismicRepo,
    // @ts-ignore
    linkResolver,
  },
])
